import { Component, Input, Output } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { User } from './user';
import { UserService } from './user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent {
  title = 'Taskus';

  currUser = null;

  navlinks = [
    {
      link: '/dashboard',
      name: 'Dashboard',
      loginOnly: true,
    },
    {
      link: '/timeline',
      name: 'Timeline View',
      loginOnly: true,
    },
    {
      link: '/new',
      name: 'New Task',
      loginOnly: true,
    },
    {
      link: '/settings',
      name: 'Settings',
      loginOnly: true,
    },
  ];

  constructor( private router: Router, private userService: UserService) {
    this.currUser = this.userService.getUser();
  }
}
