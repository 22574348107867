import { Injectable } from '@angular/core';
import { Task } from './task';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessagesService } from './messages.service';

class TaskMessage {
  status: string;
  messageType: string;
  payload: Task;
}

@Injectable({
  providedIn: 'root'
})

export class TaskService {

  taskUrl = '/api/tasks';

  tasks: Task[];

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient, private messagesService: MessagesService) { }

  getTasks(): Observable<Task[]> {
    this.http.get<Task[]>(this.taskUrl).subscribe(tasks => {
      this.tasks = tasks;
      const today = new Date();
      for (const task of tasks) {
        task.date = new Date(task.date);
        if (task.date < today) {
          task.late = true;
        }
      }
    });
    return of(this.tasks);
  }

  findTask(id: number): Task {
    for (const task of this.tasks) {
      if (task.id === id) {
        return task;
      }
    }
  }

  newTask(task: Task) {
    this.http.post<TaskMessage>(this.taskUrl, task, this.httpOptions).subscribe(newtaskmsg => {
      this.messagesService.addMessage(newtaskmsg.status, newtaskmsg.messageType);
      this.tasks.push(newtaskmsg.payload);
    },
    err => {
      this.messagesService.addMessage('Error in creating task', 'failure');
    });
  }

  editTask(task: Task) {
    this.http.put<TaskMessage>(this.taskUrl + '/' + task.id.toString(), task, this.httpOptions).subscribe(newtaskmsg => {
      this.messagesService.addMessage(newtaskmsg.status, newtaskmsg.messageType);
      this.getTasks();
    },
    err => {
      this.messagesService.addMessage('Error in editing task', 'failure');
    });
  }

  deleteTask(task: Task) {
    this.http.delete<TaskMessage>(this.taskUrl + '/' + task.id.toString(), this.httpOptions).subscribe(newtaskmsg => {
      this.messagesService.addMessage(newtaskmsg.status, newtaskmsg.messageType);
      this.getTasks();
    },
    err => {
      this.messagesService.addMessage('Error in editing task', 'failure');
    });
  }

  toggleComplete(task: Task) {
    task.completed = !task.completed;
    this.http.put<Task>(this.taskUrl + '/' + task.id.toString(), task, this.httpOptions).subscribe();
  }
}
