import { Component, OnInit } from '@angular/core';
import { Task } from '../task';
import { TaskService } from '../task.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-new-task',
  templateUrl: './new-task.component.html',
  styleUrls: ['./new-task.component.css']
})
export class NewTaskComponent implements OnInit {

  newTask = new Task();
  taskChosen = null;

  id = 0;

  newDate = {
    year: null,
    month: null,
    day: null
  };

  toggleImportant() {
    this.newTask.important = !this.newTask.important;
  }

  toggleUrgent() {
    this.newTask.urgent = !this.newTask.urgent;
  }

  submitNewTask() {
    // new date starts with month index -- minus one
    this.newTask.date = new Date(this.newDate.year, this.newDate.month - 1, this.newDate.day);
    if (this.router.url !== '/new') {
      this.taskService.editTask(this.newTask);
    } else {
      this.taskService.newTask(this.newTask);
    }
    this.newDate = {
      year: null,
      month: null,
      day: null
    };
    this.newTask = new Task();
  }

  askDelete() {
    this.taskChosen = this.newTask;
  }

  deleteTask(task: Task) {
    this.taskService.deleteTask(task);
    this.taskChosen = null;
    this.router.navigate(['/dashboard']);
  }

  constructor(private taskService: TaskService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    if (this.router.url !== '/new') {
      const idParam = 'id';
      this.route.params.subscribe( params => {
        this.id = parseInt(params[idParam], 10);
        this.newTask = this.taskService.findTask(this.id);
        const currDate = new Date(this.newTask.date);
        this.newDate.year = currDate.getFullYear();
        // month start with 0
        this.newDate.month = currDate.getMonth() + 1;
        this.newDate.day = currDate.getDate();
      });
    }
  }

}
