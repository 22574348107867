import { Component, OnInit } from '@angular/core';
import { TaskService } from '../task.service';
import { Task } from '../task';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  host: {class: 'flex-grow-1 flex-row'},
})
export class DashboardComponent implements OnInit {
  tasks = [];
  taskChosen = null;

  constructor(private taskService: TaskService) { }

  getTasks() {
    this.taskService.getTasks().subscribe(tasks => this.tasks = tasks);
  }

  chooseTask(task: Task) {
    this.taskChosen = task;
  }

  toggleTaskComplete(task: Task) {
    this.taskService.toggleComplete(task);
  }

  ngOnInit() {
    this.getTasks();
  }

}
