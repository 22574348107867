import { Component, OnInit } from '@angular/core';
import { User } from '../user';
import { UserService } from '../user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  constructor(private userService: UserService) { }

  currUser: User;

  passwordField: string;

  updateUser() {
    this.userService.updateUser(this.currUser, this.passwordField);
  }

  ngOnInit() {
    this.currUser = this.userService.currUser;
  }

}
