import { Injectable } from '@angular/core';

export class Message {
  id: number;
  status: string;
  messageType: string;
}

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  messages: Message[] = [];
  currentCount = 0;


  addMessage(status: string, messageType: string) {
    const newMessage = {id: this.currentCount, status: status, messageType: messageType};
    console.log(newMessage);
    this.currentCount++;
    console.log(typeof(newMessage));
    this.messages.push(newMessage);
    setTimeout(() => this.closeMessage(newMessage), 3000);
  }

  closeMessage(msg: Message) {
    for (let i = 0; i < this.messages.length; i++) {
      if (this.messages[i].id === msg.id) {
        this.messages.splice(i, 1);
        break;
      }
    }
  }

  constructor() {
  }
}
