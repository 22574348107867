import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;


  login() {
    this.userService.login(this.username, this.password);
  }

  routeRegister() {
    this.router.navigate(['/register']);
  }

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {
  }

}
