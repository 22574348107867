import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  username = '';
  password = '';
  email = '';
  displayname = '';

  register() {
    this.userService.register(this.username, this.password, this.email, this.displayname);
  }


  constructor(private userService: UserService) { }

  ngOnInit() {
  }

}
