import { Component, OnInit } from '@angular/core';
import { Task } from '../task';
import { TaskService } from '../task.service';
import { trigger, transition, state, style, animate } from '@angular/animations';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css'],
  animations: [ ],
})
export class TimelineComponent implements OnInit {
  tasks: Task[];

  today = (new Date()).getTime();

  getTasks() {
    this.taskService.getTasks().subscribe(tasks => this.tasks = tasks);
  }

  getDaysToTask(task: Task) {
    const diff = task.date.getTime() - this.today;
    // divide by ms
    const daysDiff = Math.ceil(diff / 86400000);
    if (daysDiff < 0) {
      return 0;
    } else {
      return daysDiff;
    }
  }

  sortTasks(tasks: Task[]): Task[] {
   tasks.sort((a, b) => {
     if (a.completed && !b.completed) {
       return 1;
     } else if (!a.completed && b.completed) {
       return -1;
     } else {
       if (a.date > b.date) {
         return 1;
       } else if (a.date < b.date) {
         return -1;
       } else {
         return 0;
       }
     }
   });
   return tasks;
  }

  toggleTaskComplete(task: Task) {
    this.taskService.toggleComplete(task);
  }

  constructor(private taskService: TaskService) { }

  ngOnInit() {
    this.getTasks();
  }

}
