export class Task {
  id: number;
  name: string;
  important: boolean;
  urgent: boolean;
  completed: boolean;
  late: boolean;
  date: Date;
  details: string;
}
